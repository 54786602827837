.el {
    position: relative;
    display: inline-block;
    margin-right: 4px;
    width:16px;
    height:16px;
    flex:0 0 16px;
    cursor: pointer;
    .el_in {
        position: absolute;
        opacity:0;
        width:0px;
        height:0px;
    }
    em {
        position: absolute;
        display: inline-block;
        width:100%;
        height:100%;
        border: 2px solid var(--theme-color-dark);
        border-radius: 2px;
        text-align: center;
    }
    .el_in:checked + em {
        background: var(--primary-color-orange);
        border:none;
    }
    .el_in:checked + em:before {
        position: absolute;
        display: inline-block;
        font-family: "Icon";
        content:"\e903";
        font-size:9px;
        color:#fff;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
    }

}