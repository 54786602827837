@import "../../base/layout";

.textfield {
    position: relative;
    width:100%;
    //max-width:280px;
    //flex: 0 1 280px;
    .password_rules{
        position: absolute;
        top: 50%;
        right: 0px;
        margin-top: -10px;
        i{
            display: inline-block;
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 50%;
            background: var(--primary-color-green);
            color: #fff;
            line-height: 1.25rem;
            font-size: 12px;
            font-weight: bold;
            text-align: center;
            font-style: normal;
            cursor: help;
        }
        ul{
            display: none;
            width: 150px;
            position: absolute;
            left: unset;
            right: -35px;
            margin-top: 2px;
            padding: 5px 10px;
            background: #fff;
            border: 1px solid;
            border-radius: 5px;
            font-size: 12px;
            z-index: 1;
            li{
                padding: 5px 0;
            }
            @media (min-width: map-get($grid-breakpoints, lg)) {
                left: -65px;
                right: unset;
            }
        }
        &:hover ul{
            display: block;
        }
    }
    .eyeBlink, .eyeOpen{
        position: absolute;
        top: 50%;
        right: 0px;
        margin-top: -10px;
        &:before{
            content: "";
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            display: inline-block;

        }
    }
    .eyeBlink{
        &:before{
            background-image: url('../../../public/static/images/myaccount/eye-closed.png');
        }
    }
    .eyeOpen{
        &:before{
            background-image: url('../../../public/static/images/myaccount/eye-open.png');
        }
    }
    .textfield__input {
        width:100%;
        padding:17px 18px;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #000000;
        font-size: 14px;
        color:#000;
        font-family: var(--font-bold);
        height:48px;
        outline:0;
        &::placeholder {
            color: var(--CLP---Text-4, #565F65);
            font-family: var(--font-regular);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        &:focus {
            outline:none;
            border-color: var(--primary-color-orange) !important;
        }
        &[disabled] {
            opacity:0.5;
            cursor: default;
            pointer-events: none;
            border: 1px solid #9394A0;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type="number"] {
            -moz-appearance: textfield;
        }
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
            -webkit-text-fill-color:#000 !important;
        }
    }
    .enabled_enter{
        display: none;
        height: 46px;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            background: #f3d782;
            color: black;
            position: absolute;
            right: 0px;
            padding: 13px 20px!important;
            margin: 1px 1px 0px 0px!important;
            border-radius: 4px;
            cursor: pointer;
            display: initial !important;
            font-family: var(--font-semi-bold);
        }
        h6{
            justify-content: center;
            text-align: center;
            padding: 0px !important;
            margin: 0px !important;
        }
        
    }
    .disabled_enter{
        display: none;
        height: 46px;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            background: #f2f2f2 ;
            color: black;
            position: absolute;
            right: 0px;
            padding: 13px 20px!important;
            border-radius: 4px;
            margin: 1px 1px 0px 0px!important;
            display: initial !important;
            font-family: var(--font-semi-bold);
            opacity: 0.8;
        }
        h6{
            justify-content: center;
            text-align: center;
            padding: 0px !important;
            margin: 0px !important;
        }
    }
    .enabled_enter_arrow{
        background: #f3d782;
        color: black;
        position: absolute;
        right: 0px;
        padding: 23px 20px;
        margin: 1px 1px 2px 0px;
        cursor: pointer;
        border-radius: 4px;
        &::before{
            background: url("../../../public/static/images/home/arrow-right.png") no-repeat;
            background-size: contain;
            background-position: center;
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            top: 32%;
            left: 25%;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            display: none;
        }
    }
    .disabled_enter_arrow{
        background-color: #f2f2f2  !important;
        color: black;
        position: absolute;
        right: 0px;
        padding: 23px 20px;
        margin: 1px 1px 1.5px 0px;
        border-radius: 4px;
        &::before{
            background: url("../../../public/static/images/home/arrow-right.png") no-repeat;
            background-size: contain;
            background-position: center;
            content: "";
            width: 20px;
            height: 20px;
            position: absolute;
            top: 32%;
            left: 25%;
        }
        @media (min-width: map-get($grid-breakpoints, lg)) {
            display: none;
        }
    }
    .is_entered_padding_right{
        padding-right: 50px;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            padding-right: 100px; 
        }
    }   
    .textfield__error {
        position: absolute;
        bottom:2px;
        left:2px;
        font-size:10px;
        font-family: var(--font-semibold);
        color:var(--font-color-error);
        transform:translateY(100%);
        @media (min-width: map-get($grid-breakpoints, md)) {
            font-size:12px;
        }
    }
    .textfield__label {
        display: block;
        margin-bottom:5px;
        font-family: var(--font-semibold);
        font-size: 14px;
        em {
            color:var(--font-color-error);
            font-family: var(--font-bold);
        }
    }
    &[data-valid="false"] {
        .textfield__input {
            border-color: var(--font-color-error) !important;
        }
    }
    & + .textfield {
        margin-top:24px;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            margin-top:0;
            margin-left: 24px;
        }
    }

    &.edit_fields__protection {
      height: 20px;
      padding: 0;
      margin-top: -5px;
      .textfield__input {
        padding: 0 8px;
        height: 20px;
        font-size: 12px;
        font-weight: 500;
    }
    @media(min-width: map-get($grid-breakpoints,lg)) {
        width: 120px;
        height:38px;
        margin-top: 0;
        .textfield__input {
          height: 38px;
      }
  }
  
}
    .textfield__disabled{
        background-color: #F8F8F3 !important;
        opacity: 0.6 !important;
    }
    span{
        @media (max-width: map-get($grid-breakpoints, sm)) {
            font-size: 13px;
        }
        padding-top:5px;
        color: var(--primary-color-green);
        font-family: var(--font-bold);
    }
    .actionRow{
        padding-top: 5px;
        display: flex;
        justify-content: space-between;
        a{
            padding-top:5px;
        @media (max-width: map-get($grid-breakpoints, sm)) {
            font-size: 13px;
        }
            color: var(--primary-color-green);
            font-family: var(--font-bold);
            cursor: pointer;
            text-decoration: underline;
        }
    }
    &[data-action="true"]{
        padding-bottom: 0px !important;
    }
}

.opt_verfication {
    width:100%
}

.enter_info{
    display: flex;
    padding: 4px 0px;
    font-family: var(--font-regular);
    font-size: 12px;
    line-height: 14px;
    color: #D0021B;
    opacity: 0.75;
    .enter_button{
        display: none;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            display: flex;
            color: #D0021B;
            font-family: var(--font-semi-bold);
            padding: 0px 2px;
        }
    }
    span{
        display: inline-block;
        background: url("../../../public/static/images/home/arrow-right.png") no-repeat;
        background-size: contain;
        background-position: center;
        background-color:rgba(104, 106, 121, 0.1);
        border: 2px solid rgba(104, 106, 121, 0);
        width: 18px;
        border-radius: 1px;
        height: 12px;
        transform: translate(0px, 2px);
        padding: 0px 2px;
        margin: 0px 2px;
        @media (min-width: map-get($grid-breakpoints, lg)) {
            display: none;
        }
    }
}

.disabled_enter_info{
    display: none;
    @media (min-width: map-get($grid-breakpoints, lg)) {
        opacity: 0;
    }
}

.activationTextField{
    border-radius: 4px !important;
    border: 1px solid var(--outline, rgba(147, 148, 160, 0.50)) !important;
    background: #FFF;
}

.calendarIcon{
    background-image: url("../../../public/static/images/activation/calendar.svg");
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 0px;
    margin: 14px;
    width: 20px;
    height: 20px;
}