@import "../base/layout";

.error {
  text-align: center;
  p {
    font-size: 18px;
    font-family: var(--font-regular);
  }
}

@keyframes spin {
  0% {
    transform: rotate(-359deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.empty_spinner {
  position: relative;
  &:empty,
  &[data-tracker-loading="true"] {
    min-height: 300px;
    &:before {
      display: inline-block;
      content: "";
      width: 30px;
      height: 30px;
      position: relative;
      top: 100px;
      border-radius: 50%;
      vertical-align: middle;
      border: 3px solid var(--primary-color-green);
      border-top-color: #b9b8b8;
      margin: auto 50%;
      transform: translateY(-50%);
      animation: spin linear 500ms infinite;
      transform-origin: center center;
    }
  }
}
.email_verification {
  padding: 25px 20px;
  display: flex;
  background: #fff;
  border: solid 1px #ededed;
  box-shadow: 0 2px 4px 0 #00000019;
  margin-top: -72px;
  flex-direction: column;
  @media (min-width: map-get($grid-breakpoints , lg )) {
    padding: 7rem;
    margin-top: -106px;
    border-radius: 8px;
  }
  &[data-varient="move-top"] {
    margin-top: -100px;
    @media (min-width: map-get($grid-breakpoints , md)) {
      margin-top: -140px;
    }
    @media (min-width: map-get($grid-breakpoints , lg )) {
      margin-top: -200px;
    }
  }
  p {
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 1.5;
    font-family: var(--font-regular);
    @media (min-width: map-get($grid-breakpoints , lg )) {
      font-size: 32px;
      margin: 20px auto;
      padding: 0 120px;
      text-align: center;
      line-height: 1.5;
    }
  }

  .image {
    margin: 0 auto;
  }

  .button {
    margin: 30px auto;
    a {
      padding: 15px 30px;
      background-color: var(--primary-color-orange);
      border-radius: 25px;
      font-family: var(--font-bold);
      cursor: pointer;
      text-decoration: none;
      color: black;
      font-size: 14px;
      @media (min-width: map-get($grid-breakpoints , md  )) {
        font-size: 18px;
        padding: 15px 85px;
      }
    }
  }
}


.input_parent {
  @media (max-width: map-get($grid-breakpoints , md )) {
    margin-top: -80px;
  }
  .text_box {
    min-height: 250px;
    .signup_message {
      margin-top: 5px;
      font-family: var(--font-bold);
      color: var(--primary-color-green);
    }
    .password {
      margin-top: 0px;
    }
  }
  .button {
    margin-top: 0px !important;
    text-align: center;
    a {
      padding: 15px 85px;
      font-size: 14px;
      @media (min-width: map-get($grid-breakpoints , lg )) {
        font-size: 18px;
      }
    }
  }

  .disabled{
    a {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .button_actions_sticky {
  }
}

.right_content {
  .input_parent_email {
    @media (max-width: map-get($grid-breakpoints , md )) {
      margin-top: -80px;
    }
    .text_box {
      .signup_message {
        margin-top: 5px;
        font-family: var(--font-bold);
        color: var(--primary-color-green);
      }
      .password {
        margin-bottom: 10px;
        margin-top: 0px;
      }
    }
    .button {
      margin-top: 30px !important;
      text-align: center;

      a {
        padding: 15px 85px;
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 18px;
        }
      }
    }
    div {
      .button_actions_sticky {
        padding: 15px 85px;
        text-align: center;
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 18px;
        }
      }
    }
    .tnc {
      margin-top: 10px;
      line-height: 1.5;
      .top_heading {
        text-align: center;
        a {
          text-decoration: underline;
          color: var(--primary-color-green);
        }
      }
    }
  }
  .input_parent_email_details {
    @media (max-width: map-get($grid-breakpoints , md )) {
      margin-top: -80px;
    }
    @media (min-width: map-get($grid-breakpoints , lg )) {
      margin-top: -100px;
    }
    
    .text_box {
      min-height: 250px;
      .signup_message {
        margin-top: 5px;
        font-family: var(--font-bold);
        color: var(--primary-color-green);
      }
      .password {
        margin-top: 5px;
      }
    }
    .button {
      margin-top: 0px !important;
      text-align: center;
      a {
        padding: 15px 85px;
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 18px;
        }
      }
    }
    .button_actions_sticky {
      text-align: center;
      font-size: 14px;
      @media (min-width: map-get($grid-breakpoints , lg )) {
        font-size: 18px;
      }
    }
  }
}

.right_content {
  .input_parent_phone {
    @media (max-width: map-get($grid-breakpoints , md )) {
      margin-top: -80px;
    }
    .text_box {
      .signup_message {
        margin-top: 5px;
        font-family: var(--font-bold);
        color: var(--primary-color-green);
      }
      .password {
        margin-top: 0px !important;
        margin-bottom: 10px;
      }
    }
    .button {
      margin-top: 50px !important;
      text-align: center;

      a {
        padding: 15px 85px;
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 18px;
        }
      }
    }

    .disabled{
      a {
        opacity: 0.3;
        pointer-events: none;
      }
    }
    div {
      .button_actions_sticky {
        padding: 15px 85px;
        text-align: center;
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 18px;
        }
      }
    }
    .top_heading {
      text-align: center;
      .tnc {
        a {
          text-decoration: underline;
          color: var(--primary-color-green);
        }
      }
        .password {
          margin-top: 0px !important;
          margin-bottom: 10px;
        }
      
    }
  }
  .input_parent_phone_details {
    @media (max-width: map-get($grid-breakpoints , md )) {
      margin-top: -80px;
    }
    @media (min-width: map-get($grid-breakpoints , lg )) {
      margin-top: -100px;
    }
    .text_box {
      min-height: 250px;
      .signup_message {
        margin-top: 5px;
        font-family: var(--font-bold);
        color: var(--primary-color-green);
      }
      .password {
        margin-top: 20px;
      }
    }
    .button {
      margin-top: 0px !important;
      text-align: center;
      a {
        padding: 15px 85px;
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 18px;
        }
      }
    }
    .button_actions_sticky {
      text-align: center;
      a {
        padding: 15px 40px;
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 18px;
        }
      }
    }
  }
  .input_parent_verify_email{
    .text_box {
      min-height: 250px;
      .signup_message {
        margin-top: 5px;
        font-family: var(--font-bold);
        color: var(--primary-color-green);
      }
      .password {
        margin-top: 20px;
      }
    }
    .button {
      margin-top: 0px !important;
      text-align: center;
      a {
        padding: 15px 85px;
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 18px;
        }
      }
    }
    .button_actions_sticky {
      text-align: center;
      padding-top: 1rem;
      a {
        padding: 15px 40px;
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 18px;
        }
      }
    }
  }
}

.password {
  label {
    font-family: var(--font-regular);
    color: black;
    opacity: 0.6;
    &[data-shrink="true"] {
      font-size: 20px;
    }
    font-size: 20px;
  }

  margin-left: 0px;
  margin-top: 20px;
  div {
    ::before {
      border-bottom: 2px solid #b1b1b1;
    }
    :after {
      border-bottom: 2px solid #fcba00;
    }
  }
  input {
    border: none;
    font-size: 20px;
  }
  p {
    color: red;
    font-family: var(--font-regular);
  }
}

.container{
  margin-top: -72px;
  @media (min-width: map-get($grid-breakpoints , lg )) {
    margin-top: -192px;
    border-radius: 8px;
    background-color: white;
  }
  &[data-varient="move-top"] {
    margin-top: -100px;
    @media (min-width: map-get($grid-breakpoints , md)) {
      margin-top: -140px;
    }
    @media (min-width: map-get($grid-breakpoints , lg )) {
      margin-top: -200px;
    }
  }
  .success_img_web{
    border: solid 1px #ededed;
    border-top: 0px;
    background-color: white;
    display: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    @media (min-width: map-get($grid-breakpoints , md )) {
      display:flex;
      a{
        display: flex;
      }
    }
  }
  .success_img_mv{
    border: solid 1px #ededed;
    border-top: 0px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    a{
      display: flex;
    }
    @media (min-width: map-get($grid-breakpoints , md )) {
      display:none;
    }
  }
  .kyc_header{
    border-radius: 8px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: white;
    border: solid 1px #ededed;
    box-shadow: 0 2px 4px 0 #00000019;
    border-bottom: none;
    display: flex;
    padding: 30px 10px;
    margin: auto 0;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    @media (min-width: map-get($grid-breakpoints , lg )) {
      flex-direction: row;
    }
    .success_img{
      width: 32px;
      height: 32px;
      margin-bottom: 10px;
      @media (min-width: map-get($grid-breakpoints , md )) {
        width: 48px;
        height: 48px;
        margin-bottom: 0px;
      }
    }
    .success_msg{
      @media (min-width: map-get($grid-breakpoints , lg )) {
        margin-left: 15px;
      }
      p{
        margin-top: 5px;
        color:#3E4054;
        font-size: 12px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 16px;
        }
      }
      h5{
        font-size: 14px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 24px;
        }
      }
    }
  }

  .reset_password {
    .password_div {
      text-align: right;
      padding-top: 0.5rem;
      padding-bottom: 1.5rem;
      .forgot_passsword {
        color: var(--primary-color-green);
        font-family: var(--font-bold);
        cursor: pointer;
      }
    }
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    background: #fff;
    border: solid 1px #ededed;
    border-bottom: 0px;
    // box-shadow: 0 2px 4px 0 #00000019;
    @media (min-width: map-get($grid-breakpoints , lg )) {
      padding: 7rem;
      flex-direction: row;
    }
    &[data-varient="move-top"] {
      margin-top: -100px;
      @media (min-width: map-get($grid-breakpoints , md)) {
        margin-top: -140px;
      }
      @media (min-width: map-get($grid-breakpoints , lg )) {
        margin-top: -200px;
      }
    }
    .left_content {
      padding: 10px;
      .heading {
        padding-bottom: 1rem;
      }
      .subheading {
        padding-bottom: 1rem;
      }
      .warning{
        color: #757575;
        font-family: var(--font-bold);
        span{
          vertical-align: middle;
        }
        div{
          vertical-align: middle;
        }
      }
      .span {
        font-family: var(--font-bold);
      }
    }
    .right_content {
      .whatsapp_opt_in_label {
        @media (max-width: map-get($grid-breakpoints , sm)) {
          font-size: 14px;
        }
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 16px;
      }
      .whatsapp {
        margin-top: 20px;
      }
      .email_activation_row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--primary-color-green);
        font-size: 1rem;
        font-family: var(--font-bold);
        .grey_text {
          margin-top: 5px;
          color: var(--primary-color-green);
          font-family: var(--font-bold);
        }
        a {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .grey_text {
        margin-top: 5px;
        color: var(--primary-color-green);
        font-family: var(--font-bold);
      }
      margin-top: 72px;
      .password {
        label {
          font-family: var(--font-regular);
          color: black;
          opacity: 0.6;
          &[data-shrink="true"] {
            font-size: 20px;
          }
          font-size: 20px;
        }

        margin-left: 0px;
        margin-top: 5px;
        padding-bottom: 21px;
        div {
          ::before {
            border-bottom: 2px solid #b1b1b1;
          }
          :after {
            border-bottom: 2px solid #fcba00;
          }
        }
        input {
          border: none;
          font-size: 20px;
        }
        p {
          font-size: 12px;
          width: 290px;
          color: red;
          top: 100%;
          position: absolute;
          font-family: var(--font-regular)
        }
        
      }
      .button {
        margin-top: 100px;
        margin-bottom: 0px;
        text-align: center;
        a {
          padding: 15px 35px;
          background-color: var(--primary-color-orange);
          color: black;
          text-decoration: none;
          cursor: pointer;
          border-radius: 25px;
          font-family: var(--font-bold);
          font-size: 14px;
          @media (min-width: map-get($grid-breakpoints , lg )) {
            font-size: 18px;
          }
        }
      }
      .disabled{
        a {
          opacity: 0.3;
          pointer-events: none;
        }
      }
      h6 {
        text-align: center;
        line-height: 2;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          padding: 0 50px;
          margin-bottom: 30px;
        }
      }
      h3 {
        text-align: center;
        font-family: var(--font-bold);
      }
      .send_otp {
        margin-top: 5px;
        text-align: end;
        color: var(--primary-color-green);
        font-family: var(--font-bold);
        cursor: pointer;
      }
      .grey_text {
        margin-top: 5px;
        color: var(--primary-color-green);
        font-family: var(--font-bold);
      }
      .reset_password_text{
        font-family: var(--font-regular);
        font-size:20px;
        @media (min-width: map-get($grid-breakpoints , lg )) {
          font-size: 32px;
        }
      }
    }
  }
}