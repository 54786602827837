@import "../../base/layout";

.banner {
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--primary-color-green);
    min-height: 200px;
    text-align: left;
    color: var(--font-color-light);

    @media (min-width: map-get($grid-breakpoints, md)) {

        &:before,
        &:after {
            position: absolute;
            content: "";
            bottom: 0;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        &:before {
            width: 152px;
            height: 120px;
            left: 0;
            background-image: url(../../../public/static/images/g-left.svg);
        }

        &:after {
            width: 200px;
            height: 257px;
            right: 0;
            background-image: url(../../../public/static/images/g-right.svg);
        }
    }

    p {
        font-size: 12px;
        font-family: var(--font-regular);
        line-height: 1.33;
    }

    ._typography {
        margin-top: 30px;
        text-align: center;
    }

    @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
        min-height: 280px;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
        text-align: center;
        min-height: 380px;

        &.order_summary {
            min-height: 421px;
        }

        padding-bottom: 115px;

        ._typography {
            margin-top: auto;
        }

        p {
            font-size: 20px;
        }
    }

    .breadcrumb {
        text-align: left;
        padding-top: 24px;
        font-size: 14px;
        line-height: 16px;
        color: #f8f8f3;
        scroll-behavior: auto;
        display: flex;
        a{
            color: #f8f8f3;
            text-decoration: none;
        }
        @media (min-width: 321px) and (max-width : 359px){
            padding-top: 36px;
          }
    }

}